<template>
    <div class="relative shadow p-5 rounded-lg bg-white dark:bg-gray-750">
        <template v-if="messagesLoading">
            <div class="absolute top-0 left-0 w-full h-full z-40 backdrop-blur-sm flex items-center justify-center">
                <Loading />
            </div>
        </template>

        <div class="flex justify-between align-center">
            <h2 class="font-semibold text-2xl dark:text-gray-300 mb-5">Ziņas</h2>

            <Button icon="plus" @click="showForm('addNewsForm')" />
        </div>

        <template v-if="displayForm('addNewsForm')">
            <AddNews :component="component" />
        </template>

        <template v-else>
            <ul role="list" class="space-y-4 max-h-[380px] overflow-auto">
                <template v-if="messages && messages.data">
                    <template v-for="message in messages.data" :key="message.id">
                        <ShowNewsListItem :message="message" :component="component" />
                    </template>
                </template>

                <template v-if="messages && messages.data && messages.data.length == 0">
                    <div class="mx-auto">
                        <img class="max-h-64 mx-auto" src="/no_messages.png">
                    </div>
                </template>

            </ul>
            <template v-if="this.messages && this.messages.meta && this.messages.meta.current_page && this.messages.meta.last_page > 1">
                <div class="flex items-center justify-end sm:px-6 pt-3">
                    <div class="flex-1 flex items-center justify-end">
                        <div>
                            <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                                <button @click="changPage(-1)" :disabled="this.messages.meta.current_page == 1" type="button" class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-750 text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-40">
                                    <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                                    </svg>
                                </button>

                                <button @click="changPage(1)" :disabled="this.messages.meta.current_page == this.messages.meta.last_page" type="button" class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-750 text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-40">
                                    <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                    </svg>
                                </button>
                            </nav>
                        </div>
                    </div>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import { defineAsyncComponent } from 'vue'

const ShowNewsListItem = defineAsyncComponent(() =>
  import('@/components/Home/News/ShowNewsListItem'))
const AddNews = defineAsyncComponent(() =>
  import('@/components/Home/News/AddNews'))
const Loading = defineAsyncComponent(() =>
  import('@/components/Components/Loading'))

export default {
    name: "NewsWidget",
    components: {
        ShowNewsListItem,
        AddNews,
        Loading,
    },
    props: {
        component: {
            type: Object
        }
    },
    data() {
        return {
            showAddForm: false,
            page: 1,
        };
    },
    computed: {
        ...mapGetters({
            user: "user",
            messages: "messages",
            formsForDisplay: "formsForDisplay",
            messagesLoading: "messagesLoading",
        }),
    },
    created() {
        this.$store.dispatch("getMessages", {id: this.component.id, page: this.page})
    },
    methods: {
        showForm(formName) {
            this.$store.dispatch("addFormForDisplay", formName);
        },
        displayForm(formName) {
            return this.formsForDisplay.includes(formName);
        },
        changPage(number) {
            this.page = this.page + number

            if (this.page < 1) {
                this.page = 1
            }
            if (this.page > this.messages.meta.last_page) {
                this.page = this.messages.meta.last_page
            }
            this.$store.dispatch("getMessages", {id: this.component.id, page: this.page})
        }
    },
}
</script>

<style>

</style>